const overrides = {
    //     MuiAvatar: {
    //         styleOverrides: {
    //             root: {
    //                 borderRadius: 6
    //             }
    //         }
    //     },
    //     MuiIconButton: {
    //         styleOverrides: {
    //             root: {
    //                 borderRadius: 6,
    //                 '&:hover': {
    //                     boxShadow: 'unset',
    //                     backgroundColor: 'transparent'
    //                 }
    //             }
    //         }
    //     },
    //     MuiMenuItem: {
    //         styleOverrides: {
    //             root: {
    //                 '&.Mui-selected': {
    //                     backgroundColor: '#4584ff'
    //                 },
    //                 '&:hover': {
    //                     backgroundColor: '#54aeff52'
    //                 }
    //             }
    //         }
    //     },
    //     MuiListItemButton: {
    //         styleOverrides: {
    //             root: {
    //                 borderRadius: 0,
    //                 '&:hover': {
    //                     backgroundColor: '#54aeff52'
    //                 }
    //             }
    //         }
    //     },
    //     MuiButtonBase: {
    //         defaultProps: {
    //             disableRipple: true
    //         },
    //         styleOverrides: {
    //             root: {
    //                 '&:hover': {
    //                     boxShadow: 'unset'
    //                 }
    //             }
    //         }
    //     },
    MuiButton: {
        styleOverrides: {
            root: {
                boxShadow: 'unset',
                '&:hover': {
                    boxShadow: 'unset',
                }
            }
        }
    }
};

export const light = {
    palette: {
        mode: 'light',
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: "'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
        fontSize: 13
    },
    shape: {
        borderRadius: 4
    },
    components: overrides
};

export const dark = {
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: "'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
        fontSize: 13
    },
    shape: {
        borderRadius: 4
    },
    components: overrides
};
