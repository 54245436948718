import axios from "axios";
import { toast } from "react-toastify"
import { baseUrl, uploadUrl } from "../config/constant";
import { getSession, removeSession, setSession } from "../utilis/auth";

const getUrl = () => {
    let path = window.location.pathname;
    switch (path.split("/")[1]) {
        case "main":
            return 'table'
        case "price":
        case "api":
            return 'price'
        default:
            return "table"
    }
}

const Axios = async (method, url, data = {}) => {
    url = baseUrl + url
    return new Promise(async function (resolve, reject) {
        const options = {
            method,
            url,
            data,
            headers: {
                "Content-Type": "application/json",
                "timeout": 10000
            }
        };

        if (getSession()) {
            options.headers["authorization"] = `Bearer ${getSession()}`;
        }

        axios(options).then((rdata) => {
            if (rdata.status === 200 && rdata.data) {
                if (!rdata.data.status && rdata.data.logout === true) {
                    removeSession();
                    toast.error("Session cleared")
                    setTimeout(() => {
                        window.location.assign("/")
                    }, 3000);
                } else {
                    if (rdata.data.newToken) {
                        setSession(rdata.data.newToken)
                    }
                    resolve(rdata.data)
                }

            } else {
                resolve({ status: false, data: "connect error" })
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    resolve(error.response.data)
                }
                return { status: false, error: "error" }
            }
        })
    })
}

export default Axios;

export const fileAxios = async (url, data, setProgress) => {
    url = baseUrl + url
    return new Promise(async function (resolve, reject) {
        const options = {
            method: 'POST',
            url,
            data,
            headers: {
                "authorization": `Bearer ${getSession()}`,
                "Content-Type": 'multipart/form-data',
                "timeout": 10000
            },
            onUploadProgress: (udata) => {
                setProgress(Math.round((100 * udata.loaded) / udata.total))
            },
        };
        axios(options).then((rdata) => {
            if (rdata.status === 200 && rdata.data) {
                resolve(rdata.data)
            } else {
                resolve({ status: false, data: "connect error" })
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 401) {
                    resolve(error.response.data)
                }
                return { status: false, error: "error" }
            }
        })
    })
}

export const updateBody = async (data) => {
    Axios('POST', `${getUrl()}/updateRow`, { data }).then((rdata) => {
        if (!rdata.status) alert('server error!')
        return rdata;
    })
}

export const downloadFile = async (data) => {
    axios.get(`${uploadUrl}${data.filename}`, { responseType: 'blob' }).then(res => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([res.data]));
        link.setAttribute("download", data.originalname);
        link.click();
    })
}

export const sessionCheck = async () => {
    return await Axios('POST', 'auth/session-check', { token: getSession() })
}

export const login = async (values) => {
    return await Axios('POST', 'auth/login', values)
}

export const addColumn = async (data) => {
    return await Axios('POST', `${getUrl()}/addColumn`, data)
}

export const removeColumn = (data) => {
    Axios('POST', `${getUrl()}/removeColumn`, data).then((rdata) => {
        if (!rdata.status) {
            alert('server error')
        }
    })
}

export const updateHeader = (data) => {
    Axios('POST', `${getUrl()}/updateHeader`, { data }).then((rdata) => {
        if (!rdata.status) alert('server error!')
    })
}

export const createUser = async (data) => {
    return await Axios('POST', 'auth/register', { ...data.data, id: data.id })
}

export const deleteUser = async (data) => {
    return await Axios('POST', 'auth/delete-user', { id: data })
}

export const updateUser = async (data) => {
    return await Axios('POST', 'auth/update-user', { data })
}

export const setPassword = async (data) => {
    return await Axios('POST', 'auth/update-password', { data });
}

export const changePassword = async (data) => {
    return await Axios('POST', 'auth/change-password', { data });
}

export const getFirstData = async (user) => {
    return await Axios('POST', `${getUrl()}/get`, { user });
}

export const updateRow = async (param) => {
    return await Axios('POST', `${getUrl()}/updateRow`, param);
}

export const updateLog = async (param) => {
    return await Axios('POST', `${getUrl()}/updateLog`, { data: param });
}

export const deleteLog = async (id) => {
    return await Axios('POST', `${getUrl()}/deleteLog`, { data: id });
}

export const getLog = async (data) => {
    return await Axios('POST', `${getUrl()}/getLog`, data);
}

export const updateNotification = async (param) => {
    return await Axios('POST', `${getUrl()}/updateNotification'`, param);
}

export const deleteSelected = async (ids) => {
    return await Axios('POST', `${getUrl()}/deleteSelected`, { data: ids });
}

export const updateAllowed = async (data) => {
    return await Axios('POST', `${getUrl()}/updateAllowed`, data);
}

export const updateOrder = async (data) => {
    return await Axios('POST', `${getUrl()}/updateOrder`, data);
}

export const duplicateRow = async (id, userId) => {
    return await Axios('POST', `${getUrl()}/duplicateRow`, { id, userId });
}

export const updateShowList = async (id, data) => {
    return await Axios('POST', 'auth/update-showList', { userId: id, data });
}

export const updateEditable = async (id, data) => {
    return await Axios('POST', 'auth/update-editable', { userId: id, data });
}

export const updateProcess = async (id, data, actor, action, creator) => {
    return await Axios('POST', `${getUrl()}/updateProcess`, { id, data, actor, action, creator });
}

export const addTableRow = async (data, hId, creator) => {
    return await Axios('POST', `${getUrl()}/addRow`, { data, hId, creator });
}

export const createTab = async (data) => {
    return await Axios('POST', `${getUrl()}/crateTab`, { data });
}

export const changeTab = async (id) => {
    return await Axios('POST', `${getUrl()}/changeTab`, { id });
}

export const updateTab = async (id, data) => {
    return await Axios('POST', `${getUrl()}/updateTab`, { id, data });
}

export const deleteTab = async (currentId, nextId) => {
    return await Axios('POST', `${getUrl()}/deleteTab`, { currentId, nextId });
}

export const updateHeaderOrder = async (data) => {
    return await Axios('POST', `${getUrl()}/updateHeaderOrder`, data);
}

export const fileExsit = async (filename) => {
    return await Axios('POST', `auth/file-check`, { filename });
}

export const deleteFile = async (filename) => {
    return await Axios('POST', `auth/remove-file`, { filename });
}
