import Stack from '@mui/material/Stack';
import { Outlet } from 'react-router-dom';

const FullLayout = () => (
    <Stack>
        <Outlet />
    </Stack>
);

export default FullLayout;
