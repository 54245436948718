import { lazy } from 'react';
import AuthGuard from './AuthGrid';
// project imports
import Layout from '../layouts';
import FullLayout from '../layouts/Full';
import Loadable from '../components/Loadable';

const Main = Loadable(lazy(() => import('../pages/Main')));
const Price = Loadable(lazy(() => import('../pages/Price')));
const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/Auth/Register')));

export const MainRoutes = {
    path: '/',
    element: <Layout />,
    children: [
        {
            path: '/main',
            element: (
                <AuthGuard >
                    <Main />
                </AuthGuard>
            )
        },
        {
            path: '/price',
            element: (
                <AuthGuard >
                    <Price />
                </AuthGuard>
            )
        },
    ]
};

export const AuthRoutes = {
    path: '/auth',
    element: <FullLayout />,
    children: [
        {
            path: 'login',
            element: <Login />
        },
        // {
        //     path: 'register',
        //     element: <Register />
        // }
    ]
}
