import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../contexts/config';

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const { user } = useContext(ConfigContext);

    useEffect(() => {
        if (!user || !user.email) {
            navigate('/auth/login', { replace: true });
        }
    }, [user, navigate]);

    return children;
};

export default AuthGuard;
