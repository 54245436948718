import { useRoutes, Navigate } from 'react-router-dom';

// routes
import { MainRoutes, AuthRoutes } from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([{ path: '*', element: <Navigate to="/auth/login" /> },{ path: '/', element: <Navigate to="/auth/login" /> }, MainRoutes, AuthRoutes]);
}
